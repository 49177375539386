@use "src/colors.scss";
@import '~bootstrap/scss/bootstrap';

.App {
  text-align: center;
  background-color: var(--color-gray);
  color: var(--color-lightgray);;
  height: 100vh;
  width: 100vw;
  .header {
    padding: 20px;
    font-weight: bold;
    font-size: 24px;
  }
  .typebuttons {
    margin-top: 20px;
  }
  .board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
    margin-top: 20px;
    .brow {
      display: flex;
      gap: 0px;
      justify-content: center;
    }
    .bcol {
      width: 70px;
      max-width: 70px;
      height: 70px;
      border: 1px solid gray;
      font-size: 12px;
      line-height: 12px;
      display: table;
      .center {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .bcol.complete {
      background-color: var(--color-yellow);
      color: var(--color-purple);
    }
  }
  .share {
    margin-top: 20px;
  }
  .ads {
    color: white;
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    bottom: 10px;
    width: 100vw
  }
}

@media only screen and (max-width: 360px) {
  .App {
    .board {
      .bcol {
        width: 60px;
        max-width: 60px;
        height: 60px;
        border: 1px solid gray;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  .App {
    .board {
      .bcol {
        width: 50px;
        max-width: 50px;
        height: 50px;
        border: 1px solid gray;
        font-size: 8px;
        line-height: 8px;
      }
    }
  }
}